import React, { useEffect, useState } from 'react';
import { View, Image } from 'react-native';
import Box from '../../components/box';
import Text from '../../components/text';
import getPlatformType from '../../helpers/helpers';
import { Container, ContainerScrollView, TextInputWrapper, Row, HorizontalLine, RowCol } from './index.style';
import Button from '../../components/button/Button';
import DrawerMenu from '../drawerMenu/DrawerMenu';
import ListCard from '../../components/listCard';
import ItemCounter from '../../components/itemCounter';
import DropDownPicker from '../../components/picker/DropDownPicker';
import useUtilsProvider from '../../hooks/useUtilsProvider';
import Urls from '../../services/Urls';
import { DashedContainer } from '../../components/box/Box.style';
import { Entypo } from '@expo/vector-icons';
import ajax from '../../helpers/ajaxHelper';
import { useRoute } from '@react-navigation/native';
import MarkAsComplete from '../../components/markAsComplete/MarkAsComplete';
import drawerHoc from '../../components/drawerHOC/DrawerHOC';
import TextInputComponent from '../../components/textinput/TextInputComponent';
import { showErrorToast, showSuccesToast } from '../tenants/tenantOnboarding/helpers';
import FilePicker from '../../components/filePicker/filepicker.web';

var layoutType = getPlatformType();

const PropertyDetails = ({ navigation }) => {
  const route = useRoute();
  const [state, setState] = useState({
    images: [],
    floorPlan:[],
    price_worth: '',
  });
  const [providerData, setProvidersData] = useState([]);

  const [selectedProviders, setSelectedProviderData] = useState([]);
  const { response } = useUtilsProvider(Urls.GET_PROVIDERS);
  const getFormattedData = (data) => {
    if (data && data?.length) {
      let formattedData = data;
      let temp = [];
      if (Array.isArray(formattedData)) {
        formattedData.forEach((element) => {
          temp = [...temp, { label: element.name, value: element.id }];
        });
      }
      return temp;
    } else return [];
  };

  useEffect(() => {
    if (response && response) {
      setProvidersData(response);
    }
  }, [response]);
  const _handleChange = (e) => {
    setState({ ...state, [e.label]: e.value });
  };

  const _onSave = () => {
    const requestBody = { ...state, providers: selectedProviders };
    ajax
      .post(Urls.ROOMS + '/' + route?.params?.id, requestBody)
      .then(({ data }) => {
        if (data.status) {
          // showSuccesToast(data?.data);
          navigation.navigate('PropertySection', { id: route?.params?.id });
        } else {
          // alert(data.message);
        }
      })
      .catch((error) => {
        console.log('error', error);
      });
  };
  useEffect(() => {
    ajax
      .get(Urls.Properties + '/' + route?.params?.id)
      .then(({ data }) => {
        if (data.status) {
          setState({
            ...state,
            ...{
              images: data?.payload?.images && data?.payload?.images.length ? data?.payload?.images : [],
              quantity: data?.payload?.quantity,
              description: data?.payload?.description,
              price_worth: data?.payload?.price_worth,
            },
          });
          if (data?.payload?.providerDetails && data?.payload?.providerDetails?.length) {
            setSelectedProviderData(data?.payload?.providerDetails);
          }
        } else {
          // alert(data.message);
          showErrorToast(data.message);
        }
      })
      .catch((error) => {
        console.log('error', error);
      });
  }, []);

  return (
    <>
      <ListCard
        leftIcon={'view-list-outline'}
        description={'Please add property details such as photo’s, floor plan and other amenities.'}
        title={'Property Details'}
        showPropertyCard={route.params?.name}
      />
      <MarkAsComplete
        label={'Property details'}
        flowID={route?.params?.id}
        flowtype={route?.params?.key}
        isComplete={route?.params?.status === 2 ? true : false}
      />
      <Box>
        <ItemCounter
          onChange={(e) => _handleChange({ value: e, label: 'quantity' })}
          values={state.quantity}
          items={[
            'Bedrooms',
            'Bathrooms',
            'Kitchens',
            'Living Room',
            'Dining Room',
            'Office',
            'Utility Area',
            'Basement',
            'Lift',
            'Conservatory',
            'Other rooms',
            'Garden',
            'Garage',
            'Parking Space',
            'Other',
          ]}
        />
      </Box>
      <Box style={{ marginTop: 15 }}>
        <Text fontSize={15} bold marginBottom={10}>
          Upload photos of the rooms and property
        </Text>
        <FilePicker
        accept={'image/*'}
                  style={{ marginBottom: 15, justifyContent: 'space-between' }}
                  onDataSuccess={(data) => {
                    if (data && data.length) {
                      setState({
                        ...state,
                        images: [...state.images, ...data],
                      });
                    }
                  }
                  }
                />
       
        <View style={{ marginTop: '2%', flexDirection: 'row', flexWrap: 'wrap' }}>
          {state.images.map((data, index) => (
            <DashedContainer
              style={{
                marginRight: '2%',
                marginBottom: '2%',
                height: 100,
                width: 200,
                borderRadius: '5%',
              }}
            >
              <Image
                source={{ uri: data.openbrixName }}
                style={{ height: '100%', width: '100%', borderRadius: '5%' }}
              />
              <Entypo
                name="circle-with-cross"
                size={32}
                color={'red'}
                onPress={() => {
                  let newArray = [...state.images];
                  newArray.splice(index, 1);
                  setState({
                    ...state,
                    images: newArray,
                  });
                }}
                style={{ position: 'absolute', top: '-20%', right: '-10%' }}
              />
            </DashedContainer>
          ))}
        </View>
      </Box>
      <Box style={{ marginTop: 15 }}>
        <Text fontSize={15} bold marginBottom={10}>
          Upload photos of property floor Plan
        </Text>
        <FilePicker
        accept={'image/*'}
                  style={{ marginBottom: 15, justifyContent: 'space-between' }}
                  onDataSuccess={(data) => {
                    if (data && data.length) {
                      setState({
                        ...state,
                        floorPlan: [...state.floorPlan, ...data],
                      });
                    }
                  }
                  }
                />
       
        <View style={{ marginTop: '2%', flexDirection: 'row', flexWrap: 'wrap' }}>
          {state.floorPlan.map((data, index) => (
            <DashedContainer
              style={{
                marginRight: '2%',
                marginBottom: '2%',
                height: 100,
                width: 200,
                borderRadius: '5%',
              }}
            >
              <Image
                source={{ uri: data.openbrixName }}
                style={{ height: '100%', width: '100%', borderRadius: '5%' }}
              />
              <Entypo
                name="circle-with-cross"
                size={32}
                color={'red'}
                onPress={() => {
                  let newArray = [...state.floorPlan];
                  newArray.splice(index, 1);
                  setState({
                    ...state,
                    floorPlan: newArray,
                  });
                }}
                style={{ position: 'absolute', top: '-20%', right: '-10%' }}
              />
            </DashedContainer>
          ))}
        </View>
      </Box>
      <Box style={{ marginTop: 15 }}>
        <RowCol>
          <TextInputWrapper width={'100%'}>
            <TextInputComponent
              height={130}
              maxLength={5000}
              multiline={true}
              onChangeText={(e) => _handleChange({ value: e, label: 'description' })}
              value={state?.description}
              title={'Property Description'}
            />
          </TextInputWrapper>
        </RowCol>
      </Box>
      <HorizontalLine />
      <Box>
        <TextInputComponent
          onChangeText={(e) => _handleChange({ value: e, label: 'price_worth' })}
          value={state?.price_worth}
          height={40}
          title={'Property worth (£)'}
          isAmount
        />
      </Box>
      <Button
        onPress={() => {
          _onSave();
          // navigation.navigate('PropertySection')
        }}
        childText={'Save and Continue'}
        width={150}
        style={{ alignSelf: 'flex-end', marginVertical: 30 }}
      />
    </>
  );
};

export default drawerHoc({
  Component: PropertyDetails,
  showBackButton: true,
});
