import { View, Text } from 'react-native';
import React, { useState, useEffect } from 'react';

import Urls from '../../services/Urls';
import Box from '../../components/box/Box';
import ajax from '../../helpers/ajaxHelper';
import getPlatformType from '../../helpers/helpers';
import Button from '../../components/button/Button';
import { useRoute } from '@react-navigation/native';
import PopUp from '../../components/popUp/PopupView';
import useProperties from '../../hooks/usePropertiesApi';
import drawerHoc from '../../components/drawerHOC/DrawerHOC';
import { RowCol, TextInputWrapper } from '../payment/index.style';
import { MainText, LabelText } from '../tenantsScreen/index.style';
import DropDownPicker from '../../components/picker/DropDownPicker';
import TextInputComponent from '../../components/textinput/TextInputComponent';
import DatePickerComponent from '../../components/datepicker/DatePickerComponent';
import { WSnackBar } from './../../vendor/react-native-smart-tip';
import { useSelector } from 'react-redux';
import Colors from '../../constants/Colors';

var layoutType = getPlatformType();

const AddReminder = ({ navigation }) => {
  const details = useSelector((state) => state?.Login?.userDetails);
  let route = useRoute();
  const response = useProperties(true);
  const [mode, setMode] = useState('ADD');
  const [isVisible, setIsVisible] = useState(false);
  const [reminders, setReminderTypes] = useState([]);
  const [state, setState] = useState({
    title: '',
    description: '',
    date: new Date(),
    remainder_type: '',
    property_id: '',
    added_by: '',
  });

  useEffect(() => {
    if (details?.first_name) {
      setState({ ...state, added_by: details?.first_name + ' ' + details?.last_name });
    }
  }, [details?.first_name, details?.last_name]);

  const _handleChange = (e) => {
    setState({ ...state, [e.label]: e?.value });
  };

  const _onDateChange = (e) => {
    let date = e?.value == "Invalid date" ? null : e?.value;
    setState({ ...state, [e.label]: date });
  };

  const _onSave = () => {    
    if(!state.title || !state.remainder_type || !state.property_id){
      const snackBarOpts = {
        data: 'Please complete all required field.',
        position: WSnackBar.position.TOP, // 1.TOP 2.CENTER 3.BOTTOM
        duration: WSnackBar.duration.LONG, //1.SHORT 2.LONG 3.INDEFINITE
        textColor: 'white',
        backgroundColor: 'red',
        actionText: '',
        actionTextColor: '#ff490b',
        onActionHide: (isSlideHide) => {
          // Click Action
        },
      };
      WSnackBar.show(snackBarOpts);
    }else{
        ajax
        .post(Urls.ADD_REMAINDER, state)
        .then(({ data }) => {
          if (data.status) {
            navigation.navigate('Reminder');
          }
        })
        .catch((error) => {
          console.log('error', error);
        });
    }
  };

  useEffect(() => {
    if (route?.params?.id) {
      setMode('VIEW');
      _getDetails(route?.params?.id);
    }
    ajax
      .get(Urls.GET_REM_TYPES)
      .then(({ data }) => {
        if (data?.status && data?.data?.reminderTypes) {
          let temp = [];
          data?.data?.reminderTypes?.forEach((element) => {
            temp = [...temp, { label: element.name, value: element.id }];
          });
          setReminderTypes(temp);
        }
      })
      .catch((error) => {
        console.log('error', error);
      });
  }, []);

  const _getDetails = (id) => {
    ajax
      .get(`${Urls.ADD_REMAINDER}/${id}?type=${route.params.type}`)
      .then(({ data }) => {
        if (data.status) {
          setState({
            ...state,
            ...{
              title: data?.payload?.title,
              description: data?.payload?.description,
              date: data?.payload?.date,
              remainder_type: data?.payload?.type?.id,
              property_id: data?.payload?.property?.id?.toString(),
              added_by: data?.payload?.added_by,
            },
          });
        } else {
          // alert(data.message);
        }
      })
      .catch((error) => {
        console.log('error', error);
      });
  };

  const _onDelete = () => {
    ajax
      .delete(Urls.ADD_REMAINDER + '/' + route?.params?.id)
      .then(({ data }) => {
        if (data.status) {
          if (route?.params?.id) {
            navigation.navigate('Reminder');
          }
        }
      })
      .catch((error) => {
        console.log('error', error);
      });
  };

  const isEditable = () => {
    return route.params.type == 'other';
  };

  console.log('route.params.type', route.params.type);

  const _onUpdate = () => {
    if(!state.title || !state.remainder_type || !state.property_id){
      const snackBarOpts = {
        data: 'Please complete all required field.',
        position: WSnackBar.position.TOP, // 1.TOP 2.CENTER 3.BOTTOM
        duration: WSnackBar.duration.LONG, //1.SHORT 2.LONG 3.INDEFINITE
        textColor: 'white',
        backgroundColor: 'red',
        actionText: '',
        actionTextColor: '#ff490b',
        onActionHide: (isSlideHide) => {
          // Click Action
        },
      };
      WSnackBar.show(snackBarOpts);
    }else{
      console.log(state);
      ajax
        .put(`${Urls.ADD_REMAINDER}/${route?.params?.id}`, state)
        .then(({ data }) => {
          if (data.status) {
            if (route?.params?.id) {
              navigation.navigate('Reminder');
            } else {
              navigation.navigate('Reminder');
            }
          }
        })
        .catch((error) => {
          console.log('error', error);
        });
    }
    
  };

  return (
    <>
      <MainText>{mode == 'ADD' ? 'Add new reminder' : 'Reminder Information'}</MainText>
      <Box style={{ zIndex: 999 }}>
        <RowCol>
          <TextInputWrapper width={'100%'}>
            <TextInputComponent
              onChangeText={(e) => _handleChange({ value: e, label: 'title' })}
              value={state?.title}
              height={40}
              disabled={!isEditable()}
              title={'Reminder Title'}
              error={state?.title ? false : '*Required'}
            />
                 {state?.title && state?.title.length<=50 &&
                       <Text
                        style={{
                          color: 'red',
                          textAlign: 'right',
                          marginTop: 5,
                        }}
                      >
                        {50 - state?.title.length} character left
                      </Text>}
          </TextInputWrapper>
        </RowCol>
        <RowCol style={{ zIndex: 999 }}>
          <TextInputWrapper width={layoutType != 'phone' ? '48%' : '100%'}>
            <DropDownPicker
              items={reminders}
              title={'Type'}
              defaultValue={state?.remainder_type}
              disabled={!isEditable()}
              onChangeItem={(e) => _handleChange({ value: e?.value, label: 'remainder_type' })}
              error={state?.description ? false : '*Required'}
            />
          </TextInputWrapper>
          <TextInputWrapper width={layoutType != 'phone' ? '48%' : '100%'}>
            <DatePickerComponent
              value={state?.date}
              placeholder={'Date'}
              onUpdateInput={(date) => {
                console.log('datedatedate', date);
                _onDateChange({ value: date, label: 'date' });
              }}
              isDisable={!isEditable()}
              error={state?.description ? false : '*Required'}
              minDate={new Date()}
            />
          </TextInputWrapper>
        </RowCol>
        <RowCol>
          <TextInputWrapper width={layoutType != 'phone' ? '48%' : '100%'}>
            <View style={{flexDirection:"row"}}>
            <View style={{flexDirection:"column",flex:1}}>
            <DropDownPicker
              items={response}
              title={'Property'}
              defaultValue={state?.property_id}
              disabled={!isEditable()}
              onChangeItem={(e) => _handleChange({ value: e.value, label: 'property_id' })}
              error={state?.description ? false : '*Required'}
            />
                      </View>
            {state?.property_id?<Button
            onPress={() => navigation.navigate('PropertyInfo', { id: state?.property_id})}
            type={'solid'}
            width={100}
            childText={'Go'}
            style={{ alignSelf: 'flex-end',marginLeft:10 }}
          />:null }
          </View>
          </TextInputWrapper>
          <TextInputWrapper width={layoutType != 'phone' ? '48%' : '100%'}>
            <TextInputComponent disabled={false} value={state?.added_by} height={40} title={'Added By'} />
          </TextInputWrapper>
        </RowCol>
        {/* {mode == 'ADD'? null :   <RowCol>
       <TextInputWrapper width={layoutType != 'phone' ? '48%' : '100%'}>
        <Button
              onPress={() => navigation.navigate('PropertySection' , { id: state?.property_id })}
              type={'stripe'}
              width={layoutType == 'phone' ? 130 : 170}
              childText={'Go to this Property'}
              style={{ alignSelf: 'flex-start' }}
            />
          </TextInputWrapper>
        </RowCol>} */}
      </Box>
      <Box style={{ marginTop: 20 }}>
        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
          <LabelText>Add notes</LabelText>
          <Text>optional</Text>
        </View>
        <TextInputComponent
          onChangeText={(e) => _handleChange({ value: e, label: 'description' })}
          value={state?.description}
          disabled={!isEditable()}
          multiline={true}
          height={100}
        />
           {state?.description && state?.description.length<=50 &&
                       <Text
                        style={{
                          color: 'red',
                          textAlign: 'right',
                          marginTop: 5,
                        }}
                      >
                        {50 - state?.description.length} character left
                      </Text>}
        
      </Box>
      {mode === 'VIEW' ? (
        <View
          style={{
            padding: 10,
            width: '100%',
            justifyContent: 'space-between',
            flexDirection: 'row',
            flexWrap: 'wrap',
          }}
        >
          {route.params.type === 'other' ? (
            <Button
              onPress={() => setIsVisible(true)}
              type={'stripe'}
              width={layoutType == 'phone' ? 130 : 170}
              childText={'Delete'}
              style={{ alignSelf: 'flex-end' }}
            />
          ) : null}
          <Button
            onPress={() => _onUpdate()}
            type={'solid'}
            width={layoutType == 'phone' ? 130 : 170}
            childText={'Save'}
            style={{ alignSelf: 'flex-end' }}
          />
        </View>
      ) : (
        <View style={{ padding: 10, width: '100%', justifyContent: 'space-between', flexDirection: 'row' }}>
          <Button
            onPress={() => _onSave()}
            type={'solid'}
            width={layoutType == 'phone' ? 130 : 170}
            childText={'Save & Continue'}
            style={{ alignSelf: 'flex-end' }}
          />

        </View>
      )}
      <PopUp
        modalVisible={isVisible}
        titleText={'Delete Reminder?'}
        titleColor={'#6D08C0'}
        subTitleText={'Are you sure you want to delete this reminder?'}
        subTitleColor={'black'}
        actionButtons={[
          {
            text: 'Delete',
            width: 100,
            onPress: () => {
              _onDelete();
            },
          },
          {
            text: 'Cancel',
            width: 100,
            onPress: () => {
              setIsVisible(false);
            },
          },
        ]}
        closePopup={() => setIsVisible(false)}
      />
                <View style={{ padding: 10 }}>

           <Button
            onPress={() => navigation.navigate('Reminder')}
            type={'stripe'}
            width={layoutType == 'phone' ? 130 : 170}
            childText={'Cancel'}
            color={Colors.light.red}
          />
          </View>
    </>
  );
};

export default drawerHoc({
  Component: AddReminder,
  title: 'Add Reminder',
  showBackButton: true,
});
