import React, { useEffect, useState } from 'react';
import { View, FlatList, TouchableOpacity, Pressable } from 'react-native';
import Box from '../../components/box';
import Text from '../../components/text';
import { MaterialIcons } from '@expo/vector-icons';
import getPlatformType from '../../helpers/helpers';
import { HeaderView, Section, WebText, SearchComponent } from './index.style';
import SearchCardComponent from '../../components/searchCard';
import { Row } from '../commonStyles';
import Colors from '../../constants/Colors';
import drawerHoc from '../../components/drawerHOC';
import Urls from '../../services/Urls';
import ajax from '../../helpers/ajaxHelper';
import moment from 'moment';
import Button from '../../components/button/Button';
import { navigate, navigationRef } from '../../navigation/RootNavigation';
import SortList from '../../components/sortList';
import { useIsFocused , useNavigation} from '@react-navigation/native';
import CalenderTextSlider from '../../components/calenderTextSlider';
import Grid from '@material-ui/core/Grid';
import {
  SearchButtonContainer
} from './index.style';

const layoutType = getPlatformType();
var sortData = [
  {
    name: 'Amount',
    value: 'amount',
  },
  {
    name: 'Date',
    value: 'date',
  },
];
const Payment = () => {
  const [payments, setPayments] = useState([]);
  const [incomingPayments, setIncomingPayments] = useState([]);
  const [outgoingPayments, setOutgoingPayments] = useState([]);
  const [filteredIncomingPayments, setFilteredIncomingPayments] = useState([]);
  const [filteredOutgoingPayments, setFilteredOutgoingPayments] = useState([]);
  const [incomingSort, setincomingSort] = useState('Date');
  const [outgoingSort, setoutgoingSort] = useState('Date');
  const focus = useIsFocused();
  let navigation = useNavigation();
  useEffect(() => {
    const toodayMonth = new Date().getMonth() + 1;
    const todayYear = new Date().getFullYear();
    ajax
      .get(`${Urls.Payments}/${toodayMonth}/${todayYear}`)
      .then(({ data }) => {
        if (data.status) {
          if (data?.payload) {
            setPayments(data?.payload);
            const incomingPayments = data?.payload.filter((item) => item.is_incoming);
            const outgoingPayments = data?.payload.filter((item) => !item.is_incoming);
            setIncomingPayments(incomingPayments);
            setOutgoingPayments(outgoingPayments);
            setFilteredIncomingPayments(incomingPayments);
            setFilteredOutgoingPayments(outgoingPayments);
          }
        } else {
        }
      })
      .catch((error) => {
        console.log('error', error);
      });
  }, [focus]);

  const setIncomingMonthYear = (monthAndYear) => {
    ajax
      .get(`${Urls.Payments}/${monthAndYear.month}/${monthAndYear.year}`)
      .then(({ data }) => {
        if (data.status) {
          if (data?.payload) {
            setPayments(data?.payload);
            const incomingPayments = data?.payload.filter((item) => item.is_incoming);
            setIncomingPayments(incomingPayments);
            setFilteredIncomingPayments(incomingPayments);
          }
        } else {
        }
      })
      .catch((error) => {
        console.log('error', error);
      });
  }
  const setOutgoingMonthYear = (monthAndYear) => {
    ajax
      .get(`${Urls.Payments}/${monthAndYear.month}/${monthAndYear.year}`)
      .then(({ data }) => {
        if (data.status) {
          if (data?.payload) {
            const outgoingPayments = data?.payload.filter((item) => !item.is_incoming);
            setOutgoingPayments(outgoingPayments);
            setFilteredOutgoingPayments(outgoingPayments);
          }
        } else {
        }
      })
      .catch((error) => {
        console.log('error', error);
      });
  }

  const sortIncoming = (name, value) => {
    setincomingSort(name);
    const sortedObjs = _.sortBy(incomingPayments, value);
    setFilteredIncomingPayments(sortedObjs);
  };

  const sortOutgoing = (name, value) => {
    setoutgoingSort(name);
    const sortedObjs = _.sortBy(outgoingPayments, value);
    setFilteredOutgoingPayments(sortedObjs);
  };

  const getPaymentStatus = (payment) => {
    if (payment.payment_statuses_master.name == 'Yes') {
      return 'Paid';
    }
    const now = new Date();
    const date = new Date(payment.date);
    if (date < now) {
      return 'Overdue';
    } else {
      return 'Upcoming';
    }
  };

  const getPaymentStatusColor = (payment) => {
    if (payment.payment_statuses_master.name == 'Yes') {
      return Colors.light.lightPurple;
    }
    const now = new Date();
    const date = new Date(payment.date);
    if (date < now) {
      return Colors.light.red;
    } else {
      return Colors.light.lightPurple;
    }
  };

  const _inGoingPaymentItem = (item) => {
    return (
      <TouchableOpacity
        onPress={() => navigate('PaymentIncomingDetails', { id: item?.id })}
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginBottom: 10,
          flexWrap: 'wrap',
          borderBottomWidth: 1,
          paddingBottom: 10,
          borderColor: '#cdcdcd',
        }}
      >
        <View style={{ width: layoutType == 'phone' ? '70%' : '25%' }}>
          <Text fontSize={15} fontWeight={'500'}>
            {item?.description}
          </Text>
        </View>
        {layoutType == 'phone' ? (
          <View style={{ width: '30%', marginTop: 10 }}>
            <Text fontSize={13} bold color={getPaymentStatusColor(item)}>
              {getPaymentStatus(item)}
            </Text>
          </View>
        ) : null}
        <View
          style={{
            width: layoutType == 'phone' ? '100%' : '20%',
            alignItems: layoutType == 'phone' ? 'flex-start' : 'center',
          }}
        >
          <Text fontSize={14} fontWeight={'400'}>
            N/A
          </Text>
        </View>
        <View style={{ width: layoutType == 'phone' ? '40%' : '15%' }}>
          <WebText>Amount (£)</WebText>
          <Text fontSize={15} fontWeight={'400'} marginTop={layoutType == 'phone' ? 5 : 0}>
            {item?.amount}
          </Text>
        </View>
        <View style={{ width: layoutType == 'phone' ? '40%' : '15%' }}>
          <WebText>Date</WebText>
          <Text fontSize={15} fontWeight={'400'} marginTop={layoutType == 'phone' ? 5 : 0}>
            {moment(item?.date).format('DD/MM/YYYY')}
          </Text>
        </View>
        {layoutType != 'phone' ? (
          <View style={{ width: layoutType == 'phone' ? '30%' : '15%' }}>
            <Text fontSize={14} bold color={getPaymentStatusColor(item)}>
              {getPaymentStatus(item)}
            </Text>
          </View>
        ) : null}
        <View style={{ width: layoutType == 'phone' ? '20%' : '10%' }}>
          <WebText>Paid?</WebText>
          <Text fontSize={14} bold >
            {item?.payment_statuses_master?.name}
          </Text>
          {/* {item?.is_received ? (
            <MaterialIcons
              name={'check-circle'}
              size={22}
              color={Colors.light.lightPurple}
              style={{ marginTop: layoutType == 'phone' ? 5 : 0 }}
            />
          ) : (
            <MaterialIcons
              name={'cancel'}
              size={22}
              color={Colors.light.red}
              style={{ marginTop: layoutType == 'phone' ? 5 : 0 }}
            />
          )} */}
        </View>
      </TouchableOpacity>
    );
  };

  const _outGoingPaymentItem = (item) => {
    return (
      <TouchableOpacity
        onPress={() => navigate('PaymentOutgoingDetails', { id: item?.id })}
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginBottom: 10,
          flexWrap: 'wrap',
          borderBottomWidth: 1,
          paddingBottom: 10,
          borderColor: '#cdcdcd',
        }}
      >
        <View style={{ width: layoutType == 'phone' ? '70%' : '25%' }}>
          <Text fontSize={15} fontWeight={'500'}>
            {item?.description}
          </Text>
        </View>
        {layoutType == 'phone' ? (
          <View style={{ width: '20%', marginTop: 10 }}>
            <Pressable onPress={() => navigate('PaymentOutgoingDetails', { id: item?.id, type: 'EDIT' })}>
              <Text fontSize={14} bold color={Colors.light.lightPurple}>
                Edit
              </Text>
            </Pressable>
          </View>
        ) : null}
        <View
          style={{
            width: layoutType == 'phone' ? '100%' : '20%',
            alignItems: layoutType == 'phone' ? 'flex-start' : 'center',
          }}
        >
          <Text fontSize={14} fontWeight={'400'}>
            N/A
          </Text>
        </View>
        <View style={{ width: layoutType == 'phone' ? '40%' : '15%' }}>
          <WebText>Amount (£)</WebText>
          <Text fontSize={15} fontWeight={'400'} marginTop={layoutType == 'phone' ? 5 : 0}>
            {item?.amount}
          </Text>
        </View>
        <View style={{ width: layoutType == 'phone' ? '40%' : '15%' }}>
          <WebText>Date</WebText>
          <Text fontSize={15} fontWeight={'400'} marginTop={layoutType == 'phone' ? 5 : 0}>
            {moment(item?.date).format('DD/MM/YYYY')}
          </Text>
        </View>
        {layoutType != 'phone' ? (
          <View style={{ width: layoutType == 'phone' ? '30%' : '15%', alignItems: 'center' }}>
            <Pressable onPress={() => navigate('PaymentOutgoingDetails', { id: item?.id, type: 'EDIT' })}>
              <Text fontSize={14} bold color={Colors.light.lightPurple}>
                Edit
              </Text>
            </Pressable>
          </View>
        ) : null}
        <View style={{ width: layoutType == 'phone' ? '20%' : '10%', marginTop: layoutType == 'phone' ? 15 : 0 }}>
          <MaterialIcons name={'chevron-right'} size={25} color={Colors.light.lightPurple} />
        </View>
      </TouchableOpacity>
    );
  };

  const filter = (list, search) => {
    if (search) {
      const response = list.filter((payment) => {
        return (
          payment.description?.toLowerCase().includes(search.toLowerCase()) ||
          payment.property.name.toLowerCase().includes(search.toLowerCase()) ||
          payment.property.postcode.toLowerCase().includes(search.toLowerCase()) ||
          payment.amount.toString().toLowerCase().startsWith(search.toLowerCase())
        );
      });
      return response;
    }
    return list;
  };

  const filterOutgoingPayments = (search) => {
    setFilteredOutgoingPayments(filter(outgoingPayments, search));
  };

  const filterIncomingPayments = (search) => {
    setFilteredIncomingPayments(filter(incomingPayments, search));
  };

  const downloadPaymentCSV = (isIncoming) => {
    ajax
      .get(`${Urls.PAYMENTS_CSV}?incoming=${isIncoming}`)
      .then(({ data }) => {
        if (data.status) {
          var blob = new Blob([data.payload], {type: "text/plain;charset=utf-8"}); 
          window.open(data.payload, '_blank');
        } else {
          // alert(data.message);
        }
      })
      .catch((error) => {
        console.log('error', error);
      });
  };

  const EmptyListMessage = ({ item }) => {
    return (
      // Flat List Item
      <Text>
        No payment information found
      </Text>
    );
  };

  return (
    <>
      <Row
        style={{
          justifyContent: 'space-between',
          alignItems: layoutType == 'phone' ? 'flex-end' : 'center',
          zIndex: 999,
          flexDirection: layoutType == 'phone' ? 'flex' : 'row',
          flexDirection: layoutType == 'phone' ? 'column' : 'row',
          marginBottom: layoutType == 'phone' ? 10 : 0,
        }}
      >

        <SearchComponent
          style={{
            flexGrow: 2
          }}
          placehorderText={'Search Payments'}
          onChangeText={(e) => filterIncomingPayments(e)} leftText={'Incoming Payments'} />

          <Row style={{
            gap: '5px'
          }}>
            <CalenderTextSlider 
            setMonthYear={setIncomingMonthYear} />
            <SortList 
            data={sortData} renderTitle={incomingSort} setSort={(name, value) => sortIncoming(name, value)} />
            </Row>
      </Row>
      <Section>
        <Box>
          <FlatList
            data={filteredIncomingPayments}
            style={{ flexGrow: 0 }}
            ListHeaderComponent={() => (
              <HeaderView>
                <View style={{ width: '25%', marginBottom: 10 }}>
                  <Text fontWeight={'600'} fontSize={15}>
                    Description
                  </Text>
                </View>
                <View style={{ width: '20%', marginBottom: 10, alignItems: 'center' }}>
                  <Text fontWeight={'600'} fontSize={15}>
                    Tenants
                  </Text>
                </View>
                <View style={{ width: '15%', marginBottom: 10 }}>
                  <Text fontWeight={'600'} fontSize={15}>
                    Amount (£)
                  </Text>
                </View>
                <View style={{ width: '15%', marginBottom: 10 }}>
                  <Text fontWeight={'600'} fontSize={15}>
                    Date
                  </Text>
                </View>
                <View style={{ width: '15%', marginBottom: 10 }}>
                  <Text fontWeight={'600'} fontSize={15}>
                    Status
                  </Text>
                </View>
                <View style={{ width: '10%', marginBottom: 10 }}>
                  <Text fontWeight={'600'} fontSize={15}>
                    Paid?
                  </Text>
                </View>
              </HeaderView>
            )}
            renderItem={({ item }) => _inGoingPaymentItem(item)}
            ListEmptyComponent={EmptyListMessage}
          />
        </Box>
        <Row style={{ marginTop: 10,
                justifyContent: layoutType == 'phone' ? 'space-between' : 'flex-start'
        }}>
          <Button childText={'Add New Payment'} onPress={() => navigation.push('PaymentIncomingDetails')} width={170} />
          <Button
            childText={'Download all'}
            onPress={() => downloadPaymentCSV('true')}
            width={150}
            style={{ marginLeft: 10 }}
            type={'stripe'}
          />
        </Row>
      </Section>
      <div style={{
        marginTop: layoutType == 'phone' ? 20 : 30,
      }}>
      <Row
        style={{
          justifyContent: 'space-between',
          alignItems: layoutType == 'phone' ? 'flex-end' : 'center',
          zIndex: 999,
          flexDirection: layoutType == 'phone' ? 'column' : 'row',
          marginBottom: layoutType == 'phone' ? 10 : 0,
        }}
      >
        <SearchComponent
        style={{
          flexGrow: 2,
        }}
          placehorderText={'Search Payments'}
          onChangeText={(e) => filterOutgoingPayments(e)} leftText={'Outgoing Payments'} />
          <Row
          style={{
            gap: '5px'
          }}>
           <CalenderTextSlider setMonthYear={setOutgoingMonthYear} />
        <SortList data={sortData} renderTitle={outgoingSort} setSort={(name, value) => sortOutgoing(name, value)} />
        </Row>
      </Row>
      <Section>
        <Box>
          <FlatList
            data={filteredOutgoingPayments}
            style={{ flexGrow: 0 }}
            ListHeaderComponent={() => (
              <HeaderView>
                <View style={{ width: '25%', marginBottom: 10 }}>
                  <Text fontWeight={'600'} fontSize={15}>
                    Description
                  </Text>
                </View>
                <View style={{ width: '20%', marginBottom: 10, alignItems: 'center' }}>
                  <Text fontWeight={'600'} fontSize={15}>
                    Tenants
                  </Text>
                </View>
                <View style={{ width: '15%', marginBottom: 10 }}>
                  <Text fontWeight={'600'} fontSize={15}>
                    Amount (£)
                  </Text>
                </View>
                <View style={{ width: '15%', marginBottom: 10 }}>
                  <Text fontWeight={'600'} fontSize={15}>
                    Date
                  </Text>
                </View>
                <View style={{ width: '15%', marginBottom: 10 }}>
                  <Text fontWeight={'600'} fontSize={15}></Text>
                </View>
                <View style={{ width: '10%', marginBottom: 10 }}>
                  <Text fontWeight={'600'} fontSize={15}></Text>
                </View>
              </HeaderView>
            )}
            renderItem={({ item }) => _outGoingPaymentItem(item)}
            ListEmptyComponent={EmptyListMessage}
          />
        </Box
        >
      </Section>
      <Row style={{ marginTop: 10,
          justifyContent: layoutType == 'phone' ? 'space-between' : 'flex-start'
       }}>
        <Button childText={'Add New Payment'} onPress={() => navigate('PaymentOutgoingDetails')} width={170} />
        <Button
          childText={'Download all'}
          onPress={() => downloadPaymentCSV('false')}
          width={150}
          style={{ marginLeft: 10 }}
          type={'stripe'}
        />
      </Row>
      </div>
    </>
  );
};

export default drawerHoc({ Component: Payment });
