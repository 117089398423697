import React, { useState, useEffect } from 'react';
import { View, Text, TouchableOpacity, StyleSheet } from 'react-native';


const MonthPicker = (props) => {
    const [months, setMonths] = useState([]);
    const { onSelection, selectedValue } = props
    useEffect(() => {
        const currentMonth = new Date().getMonth(); // No need to add 1 here, as the array of month names starts from index 0.
        const monthsList = [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December'
        ];
        setMonths(monthsList);
    }, []);

    const handlePrevMonth = () => {
        // Handle logic for previous month selection
    };

    const handleNextMonth = () => {
        // Handle logic for next month selection
    };

    return (
        <View style={styles.container}>
            <View style={styles.grid}>
                {months.map((month, index) => (
                    <View key={index} style={styles.item}>
                        <TouchableOpacity

                            onPress={() => onSelection(month)}
                            key={index} style={selectedValue === month ? styles.SelectedItem : { padding: 10 }}>
                            <Text style={styles.month}>{month}</Text>
                        </TouchableOpacity>
                    </View>
                ))}
            </View>
        </View>
    );
};

const styles = StyleSheet.create({
    SelectedItem: {
        alignItems: 'center',
        paddingVertical: 6,
        paddingHorizontal: 10,
        justifyContent: 'center',
        backgroundColor: "#dadada",
        borderRadius: 4
    },
    item: {
        width: '33.33%',
        alignItems: 'center',
        justifyContent: 'center',
    },
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 10
    },
    header: {
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 16,
    },
    grid: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
    },

    month: {
        fontSize: 16,
    },
});

export default MonthPicker;
