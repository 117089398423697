import React, { useState, useEffect } from 'react';
import { View, FlatList, TouchableOpacity } from 'react-native';
import StatusBar from '../../components/statusBar';
import Box from '../../components/box';
import { FontAwesome } from '@expo/vector-icons';
import Text from '../../components/text';
import DrawerMenu from '../drawerMenu/DrawerMenu';
import { MaterialIcons } from '@expo/vector-icons';
import getPlatformType from '../../helpers/helpers';
import { Left, Section } from './home.style';
import CalenderComponent from '../../components/calendar';
import SearchCardComponent from '../../components/searchCard';
import { Col, Row } from '../commonStyles';
import Colors from '../../constants/Colors';
import PaymentList from '../../components/paymentList';
import drawerHoc from '../../components/drawerHOC';
import ajax from '../../helpers/ajaxHelper';
import Urls from '../../services/Urls';
import moment from 'moment';
import { useIsFocused, useNavigation } from '@react-navigation/native';
import { navigate } from '../../navigation/RootNavigation';
import CalendarList from '../../components/calendarList';
import { RemainderSection } from '../properties/index.style';
import PlaceHolder from '../../components/placeholder';
import CalenderTextSlider from '../../components/calenderTextSlider';
import NewsTool from '../../components/newsTool';
import DocsComponent from '../../components/docsComponent';
import debounce from 'lodash.debounce';

const Home = ({ navigation }) => {
  const focused = useIsFocused();
  const [properties, setProperties] = useState([]);
  const [filterproperties, setFilterProperties] = useState([]);

  const [payment, setPayment] = useState([]);
  const [fEventsData, setFEventsData] = useState({});
  const [eventsData, setEventsData] = useState([]);
  const filterOutgoingPayments = (search) => {
    setFilterProperties(filter(properties, search));
  };

  const filter = (list, search) => {
    if (search) {
      const response = list.filter((payment) => {
        return (
          payment.name.toLowerCase().includes(search.toLowerCase()) ||
          payment.postcode.toLowerCase().includes(search.toLowerCase()) ||
          payment.city.toLowerCase().includes(search.toLowerCase())
        );
      });
      return response;
    }
    console.log('list', list);
    return list;
  };
  useEffect(() => {
    ajax
      .get(Urls.GET_RECENT_PROPERTIES)
      .then(({ data }) => {
        if (data.status && data?.payload && data?.payload?.length) {
          setProperties(data?.payload.reverse());
          setFilterProperties(data?.payload.reverse());
        } else {
        }
      })
      .catch((error) => {
        console.log('error', error);
      });
    _getCalEvents(new Date().getMonth() + 1, new Date().getFullYear());
    _getRemainderCall(new Date().getMonth() + 1, new Date().getFullYear());
  }, [focused]);
  const arrToObj = (arr, key) => {
    return arr.reduce((obj, item) => {
      obj[moment(item[key]).format('YYYY-MM-DD')] = {
        customStyles: {
          container: {
             backgroundColor:moment(item.date).isBefore(moment().format())? '#BDA6F4': '#21B1C4' ,
            // backgroundColor:'#21B1C4',
            elevation: 4,
          },
          text: {
            color: 'white',
          },
        },
      };
      return obj;
    }, {});
  };
  const _getCalEvents = (month, year) => {
    ajax
      .get(Urls.GET_REMAINDERS_MONTHLY + month + '/' + year)
      .then(({ data }) => {
        if (data.status) {
          console.log('data', data);
          if (data?.payload && data.payload.length) {
            setFEventsData(arrToObj(data?.payload, 'date'));
            setEventsData(data?.payload);
          } else {
            setFEventsData([]);
            setEventsData([]);
          }
        }
      })
      .catch((error) => {
        console.log('error', error);
      });
  };
  const _getRemainderCall = (month, year) => {
    ajax
      .get(Urls.GET_PAYMENTS_MONTHL + 'month=' + month + '&' + 'year=' + year)
      .then(({ data }) => {
        if (data.status) {
          console.log('data GET_PAYMENTS_MONTHL', data);
          if (data?.payload && data.payload.length) {
            setPayment(data?.payload);
          } else {
            setPayment([]);
          }
        }
      })
      .catch((error) => {
        console.log('error', error);
      });
  };
  const _onChange = debounce((text) => {
    _onSearch(text);
  }, 1000);
  const _onSearch = (text) => {
    ajax
      .post(Urls.SearchProperty, { searchText: text })
      .then(({ data }) => {
        if (data.status) {
          setFilterProperties(data?.data);
        } else {
          // alert(data.message);
        }
      })
      .catch((error) => {
        console.log('error', error);
      });
  };
  return (
    <>
      <SearchCardComponent
        placehorderText={'Search Properties'}
        onChangeText={(e) => {
          _onChange(e);
        }}
        leftText={'Welcome Back !'}
      />
      <Section>
        <Left>
          {filterproperties && filterproperties.length ? (
            <>
              <Text fontSize={16} fontWeight="600" marginBottom={10}>
                Recently Viewed
              </Text>
              <FlatList
                data={filterproperties}
                numColumns={2}
                style={{ flexGrow: 0 }}
                renderItem={({ item }) => (
                  <Box style={{ width: '49%', marginRight: '2%', marginTop: '2%' }}>
                    <TouchableOpacity onPress={() => navigate('PropertyInfo', { id: item?.id })}>
                      <Row style={{ alignItems: 'center' }}>
                        <View style={{ flex: 1, flexDirection: 'row', alignItems: 'center' }}>
                          <MaterialIcons name="home" color={Colors.light.lightPurple} size={20} />
                          <Text numberOfLines={1} fontSize={15} marginLeft={6}>
                            {item?.name}
                          </Text>
                        </View>
                        <MaterialIcons name="chevron-right" color={Colors.light.lightPurple} size={20} />
                      </Row>
                    </TouchableOpacity>
                  </Box>
                )}
              />
            </>
          ) : (
            <PlaceHolder
              header={'Ready to get started with Hubita?'}
              title={'Managing your own properties?'}
              onPress={() => navigation.navigate('Properties')}
              description={`Manage your properties better with Hubita, we will organise documents, licences and send you reminders.`}
              buttonText={'Add property'}
            />
          )}

          <>
            <NewsTool />
          </>
        </Left>
        <RemainderSection>
          <View style={{ justifyContent: 'space-between', flexDirection: 'row' }}>
            <Text fontWeight={600} marginTop={20} fontSize={18}>
              Reminders
            </Text>
            {eventsData && eventsData.length ? (
              <Text
                onPress={() => navigation.push('AddReminder', { type: 'other' })}
                color={Colors.light.purple}
                fontWeight={600}
                marginTop={20}
                fontSize={18}
              >
                Add
              </Text>
            ) : null}
          </View>
          <Box style={{ width: '100%', marginTop: 20, paddingHorizontal:0 }}>
            <CalenderComponent markers={fEventsData} onMonthChange={(data) => _getCalEvents(data.month, data.year)} />
            <CalendarList eventList={eventsData} />
          </Box>
        </RemainderSection>
      </Section>
    </>
  );
};
// export default Home;

export default drawerHoc({
  Component: Home,
});
