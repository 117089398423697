import React, { useState, useEffect } from 'react';
import { View, FlatList } from 'react-native';
import StatusBar from '../../components/statusBar';
import Box from '../../components/box';
import { FontAwesome } from '@expo/vector-icons';
import Text from '../../components/text';
// import DrawerMenu from '../drawerMenu/DrawerMenu';
import { MaterialIcons } from '@expo/vector-icons';
import getPlatformType from '../../helpers/helpers';
import { Container, ContainerScrollView, TextInputWrapper, Row, HorizontalLine, RowCol } from './index.style';
import CalenderComponent from '../../components/calendar';
import CalendarList from '../../components/calendarList';
import { HOME } from './../../constants/svg';
import Button from '../../components/button/Button';
import SearchCardComponent from '../../components/searchCard';
import PropertyTable from '../../components/propertyTable';
import DrawerMenu from '../drawerMenu/DrawerMenu';
import ListCard from '../../components/listCard';
import TextInputComponent from '../../components/textinput/TextInputComponent';
import ItemCounter from '../../components/itemCounter';
import RadioButton from '../../components/radioButton';
import { useRoute } from '@react-navigation/native';
import Urls from '../../services/Urls';
import ajax from '../../helpers/ajaxHelper';
import DocumentView from '../../components/documentViewer';
import drawerHoc from '../../components/drawerHOC/DrawerHOC';
import MarkAsComplete from '../../components/markAsComplete/MarkAsComplete';
var layoutType = getPlatformType();
import moment from 'moment';
import { showErrorToast ,showSuccesToast } from '../tenants/tenantOnboarding/helpers';
import FilePicker from '../../components/filePicker/filepicker.web';

const Licensing = ({ navigation }) => {
  const [hmoLicense, setHMOLicense] = useState('');
  const [additionalLicense, setAdditional] = useState('');
  const [selectiveLicense, setSelective] = useState('');
  const [otherCertificate, setOther] = useState('');

  const [state, setState] = useState({
    hmo_license: [],
    hmo_license_expiry: moment().format('MM/DD/YYYY'),
    additional_license: [],
    additional_license_expiry: moment().format('MM/DD/YYYY'),
    selective_license: [],
    selective_license_expiry: moment().format('MM/DD/YYYY'),
    other_license: [],
    other_license_expiry: moment().format('MM/DD/YYYY'),
    other_license_title: '',
  });
  const route = useRoute();
  useEffect(() => {
    if (route?.params?.id) {
      _getLicensing(route?.params?.id);
    }
  }, []);

  const _getLicensing = (id) => {
    ajax
      .get(Urls.Licensing + '/' + id)
      .then(({ data }) => {
        if (data.status) {
          setState({
            ...state,
            ...{
              hmo_license: data?.data?.hmo_license?.files ? data?.data?.hmo_license?.files : [],
              hmo_license_expiry: data?.data?.hmo_license?.expiry_date ? data?.data?.hmo_license?.expiry_date : '',

              additional_license: data?.data?.additional_license?.files ? data?.data?.additional_license?.files : [],
              additional_license_expiry: data?.data?.additional_license?.expiry_date
                ? data?.data?.additional_license?.expiry_date
                : '',

              selective_license: data?.data?.selective_license?.files ? data?.data?.selective_license?.files : [],
              selective_license_expiry: data?.data?.selective_license?.expiry_date
                ? data?.data?.selective_license?.expiry_date
                : '',

              other_license: data?.data?.other_license?.files ? data?.data?.other_license?.files : [],
              other_license_expiry: data?.data?.other_license?.expiry_date
                ? data?.data?.other_license?.expiry_date
                : '',

              other_license_title: "",
            },
          });
          if (data?.data?.hmo_license?.status) {
            setHMOLicense(data?.data?.hmo_license?.status);
          }
          if (data?.data?.additional_license?.status) {
            setAdditional(data?.data?.additional_license?.status);
          }
          if (data?.data?.selective_license?.status) {
            setSelective(data?.data?.selective_license?.status);
          }
        } else {
          // alert(data.message);
        }
      })
      .catch((error) => {
        console.log('error', error);
      });
  };
  const _onSave = (navigatePage = true) => {
    const requestBody = {
      hmo_license: {
        files: state?.hmo_license,
        expiry_date: state?.hmo_license_expiry ? state?.hmo_license_expiry : moment().format('MM/DD/YYYY'),
        status: hmoLicense,
        name: 'hmo_license',
      },
      additional_license: {
        files: state?.additional_license,
        expiry_date: state?.additional_license_expiry
          ? state?.additional_license_expiry
          : moment().format('MM/DD/YYYY'),
        status: additionalLicense,
        name: 'additional_license',
      },
      selective_license: {
        files: state?.selective_license,
        expiry_date: state?.selective_license_expiry ? state?.selective_license_expiry : moment().format('MM/DD/YYYY'),
        status: selectiveLicense,
        name: 'selective_license',
      },
      other_license: {
        files: state?.other_license,
        expiry_date: state?.other_license_expiry ? state?.other_license_expiry : moment().format('MM/DD/YYYY'),
        status: otherCertificate,
        title: state.other_license_title,
        name: 'other_license',
      },
    };
    // console.log('requestBodyrequestBody', requestBody);
    ajax
      .post(Urls.Licensing + '/' + route?.params?.id, requestBody)
      .then(({ data }) => {
        if (data.status) {
          // showSuccesToast(data?.data);
          if (navigatePage) {
            navigation.navigate('PropertySection', { id: route?.params?.id });
          }
        } else {
          // alert(data.message);
        }
      })
      .catch((error) => {
        console.log('error', error);
      });
  };
  const _onAddCertifcate = (value, tag) => {
    let obj = {
      name: tag,
      document_url: value[0]?.openbrixName,
      expiry_date: new Date(),
      file_name: tag == 'other_license' ? state?.other_license_title : value[0]?.name,
    };
    console.log('Obj', obj);
    setState({ ...state, [tag]: [...state[tag], obj],other_license_title:"",other_license_expiry:"" });
  };
  const moveToDocs =(data)=>{
    ajax
    .post(Urls.MoveToDocs + '/' + route?.params?.id, data)
    .then(({ data }) => {
      if (data.status) {
        showSuccesToast('Document moved to myDocs section!');
        _getCertifactes(route?.params?.id);
      } else {
        showErrorToast('Something went wrong!');
      }
    })
    .catch((error) => {
      showErrorToast('Something went wrong!');
    });
  }
  const _onRemove = (obj, tag, i,ismoveToDocs) => {
    let arr = state[tag];
    if(ismoveToDocs){
      moveToDocs(arr[i])
    }else{
      arr[i].is_deleted = true;
      setState({ ...state, [tag]: arr });
      _onSave(false);
    }
  };
  const GetBeuatyName = (name) => {
    if (name && name.length > 0) {
      let splitname = name.length>15?name.substring(0,15):name;
      return splitname;
    }
  };
  return (
    <>
      <ListCard leftIcon={'file-star'} 
      description={'Please add the local authority licencing requirements that is required.'}
      title={'Licencing'} 
      showPropertyCard={route.params?.name}
       />
      <MarkAsComplete
        label={'Licensing requirements'}
        flowID={route?.params?.id}
        flowtype={route?.params?.key}
        isComplete={route?.params?.status === 2 ? true : false}
      />
      <Box
        style={{
          marginTop: 15,
          marginBottom: 15,
          backgroundColor:  '#fff',
        }}
      >
        <RadioButton
          value={hmoLicense}

          options={['Yes', 'No', 'Not Applicable']}
          onChange={(e) => {
            setHMOLicense(e);
            
          }}
          title={'HMO Licence'}
        />
        {hmoLicense == 'Yes' && (
          <>
            <Row
              style={{ marginTop: 20, width: layoutType == 'phone' ? '100%' : '60%', justifyContent: 'space-between' }}
            >
              <TextInputComponent
                onChangeText={(e) => setState({ ...state, hmo_license_expiry: e })}
                isDate
                date={state?.hmo_license_expiry}
                title={'Expire Date'}
              ></TextInputComponent>
            </Row>
            <View>
                <Text fontSize={15} bold marginBottom={10}>
                  Upload Documents
                </Text>
                {/* <Button
                  onDataSuccess={(data) => _onAddCertifcate(data, 'epc_certificate')}
                  type={'upload'}
                  childText={'Upload'}
                /> */}
                <FilePicker
                  style={{ marginBottom: 15, justifyContent: 'space-between' }}
                  onDataSuccess={(data) => _onAddCertifcate(data, 'hmo_license')}
                  />
              </View>
            {state?.hmo_license &&
              state?.hmo_license.map((e, i) => {
                if (e?.is_deleted) {
                  return null;
                } else {
                  return (
                    <DocumentView
                      title={GetBeuatyName(e?.file_name)}
                      url={e?.document_url}
                      onDelete={(moveToDocs) => _onRemove(e, 'hmo_license', i,moveToDocs)}
                    />
                  );
                }
              })}
          </>
        )}
      </Box>
      <Box style={{backgroundColor:'#fff' }}>
        <RadioButton
          value={additionalLicense}
          options={['Yes', 'No', 'Not Applicable']}
          onChange={(e) => {
            setAdditional(e);
          }}
      
          title={'Additional Licence'}
        />
        {additionalLicense == 'Yes' && (
          <>
            <Row
              style={{ marginTop: 20, width: layoutType == 'phone' ? '100%' : '60%', justifyContent: 'space-between' }}
            >
              <TextInputComponent
                onChangeText={(e) => setState({ ...state, additional_license_expiry: e })}
                title={'Expire Date'}
                isDate
                date={state?.additional_license_expiry}
              ></TextInputComponent>
              
            </Row>
            <View>
                <Text fontSize={15} bold marginBottom={10}>
                  Upload Documents
                </Text>
                {/* <Button
                  onDataSuccess={(data) => _onAddCertifcate(data, 'epc_certificate')}
                  type={'upload'}
                  childText={'Upload'}
                /> */}
                <FilePicker
                  style={{ marginBottom: 15, justifyContent: 'space-between' }}
                  onDataSuccess={(data) => _onAddCertifcate(data, 'additional_license')}
                  />
              </View>
            {state?.additional_license &&
              state?.additional_license.map((e, i) => {
                if (e?.is_deleted) {
                  return null;
                } else {
                  return (
                    <DocumentView
                      title={e?.file_name}
                      url={e?.document_url}
                      onDelete={(moveToDocs) => _onRemove(e, 'additional_license', i,moveToDocs)}
                    />
                  );
                }
              })}
          </>
        )}
      </Box>
      <Box
        style={{
          marginTop: 15,
          backgroundColor:'#fff',
        }}
      >
        <RadioButton
          options={['Yes', 'No', 'Not Applicable']}
          onChange={(e) => {
            setSelective(e);
            
          }}

          title={'Selective Licence'}
          value={selectiveLicense}
        />
        {selectiveLicense == 'Yes' && (
          <>
            <Row
              style={{ marginTop: 20, width: layoutType == 'phone' ? '100%' : '60%', justifyContent: 'space-between' }}
            >
              <TextInputComponent
                onChangeText={(e) => setState({ ...state, selective_license_expiry: e })}
                title={'Expire Date'}
                isDate
                date={state?.selective_license_expiry}
              ></TextInputComponent>
            </Row>
            <View>
                <Text fontSize={15} bold marginBottom={10}>
                  Upload Documents
                </Text>
                {/* <Button
                  onDataSuccess={(data) => _onAddCertifcate(data, 'epc_certificate')}
                  type={'upload'}
                  childText={'Upload'}
                /> */}
                <FilePicker
                  style={{ marginBottom: 15, justifyContent: 'space-between' }}
                  onDataSuccess={(data) => _onAddCertifcate(data, 'selective_license')}
                  />
              </View>
            {state?.selective_license &&
              state?.selective_license.map((e, i) => {
                if (e?.is_deleted) {
                  return null;
                } else {
                  return (
                    <DocumentView
                      title={e?.file_name}
                      url={e?.document_url}
                      onDelete={(moveToDocs) => _onRemove(e, 'selective_license', i,moveToDocs)}
                    />
                  );
                }
              })}
          </>
        )}
      </Box>

      <Text fontWeight={600} marginTop={10} marginBottom={10} fontSize={18} marginLeft={10}>
        Other Licence
      </Text>
      <Box>
        <RowCol>
          <TextInputWrapper width={'100%'}>
            <TextInputComponent
              onChangeText={(e) => setState({ ...state, other_license_title: e })}
              height={40}
              title={'Licence title'}
              value={state?.other_license_title}
            />
                  {state?.other_license_title && state?.other_license_title.length<=50 &&
                       <Text
                        style={{
                          color: 'red',
                          textAlign: 'right',
                          marginTop: 5,
                        }}
                      >
                        {50 - state?.other_license_title.length} character left
                      </Text>}
          </TextInputWrapper>
        </RowCol>
        <Row style={{ marginTop: 20, width: layoutType == 'phone' ? '100%' : '60%', justifyContent: 'space-between' }}>
          <TextInputComponent
            title={'Expire Date'}
            onChangeText={(e) => setState({ ...state, other_license_expiry: e })}
            isDate
            date={state?.other_license_expiry}
          ></TextInputComponent>
        </Row>
        <View>
                <Text fontSize={15} bold marginBottom={10}>
                  Upload Documents
                </Text>
                {/* <Button
                  onDataSuccess={(data) => _onAddCertifcate(data, 'epc_certificate')}
                  type={'upload'}
                  childText={'Upload'}
                /> */}
                <FilePicker
                  style={{ marginBottom: 15, justifyContent: 'space-between' }}
                  onDataSuccess={(data) => _onAddCertifcate(data, 'other_license')}
                  />
              </View>
        {state?.other_license &&
          state?.other_license.map((e, i) => {
            if (e?.is_deleted) {
              return null;
            } else {
              return (
                <DocumentView
                  title={e?.file_name}
                  url={e?.document_url}
                  onDelete={(moveToDocs) => _onRemove(e, 'other_license', i,moveToDocs)}
                />
              );
            }
          })}
      </Box>
      <Button
        onPress={() => _onSave()}
        childText={'Save and Continue'}
        width={150}
        style={{ alignSelf: 'flex-end', marginVertical: 30 }}
      />
    </>
  );
};

export default drawerHoc({
  Component: Licensing,
  showBackButton: true,
});
