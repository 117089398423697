import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { format, addYears } from 'date-fns';
import { StyleSheet, View } from 'react-native';
import { Calendar, LocaleConfig,CalendarUtils } from 'react-native-calendars';
import { Octicons } from '@expo/vector-icons';
import moment from 'moment';
import Text from '../text';
import { TouchableOpacity } from 'react-native-gesture-handler';
import MonthPicker from './monthPicker';
import AntDesign from '@expo/vector-icons/AntDesign';

LocaleConfig.locales['en'] = {
  formatAccessibilityLabel: "dddd d 'of' MMMM 'of' yyyy",
  monthNames: [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sept',
    'Oct',
    'Nov',
    'Dec',
  ],
  monthNamesShort: ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'],
  dayNames: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
  dayNamesShort: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
};

LocaleConfig.defaultLocale = 'en';

const INITIAL_DATE = '2020-02-02';

const CalenderComponent = (props) => {
  const { markedDates, theme, minDate, maxDate, disabledDates, onMonthChange, markers } = props;
  const [selected, setSelected] = useState(INITIAL_DATE);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const onDayPress = (day) => {
    setSelected(day.dateString);
  };
  const [calType, setCalType] = useState("cal");
  const handleMonthChange = (action) => {
    const newDate = new Date(selectedDate); // Create a new date object based on the selectedDate

    if (action === "add") {
      newDate.setMonth(newDate.getMonth() + 1);
    } else if (action === "subtract") {
      newDate.setMonth(newDate.getMonth() - 1);
    }
    setSelectedDate(newDate);
  };
  const getMonthAndYear = (date) => {
    const month = moment(date).format("MMMM"); // Format the month as 'January'
    const year = moment(date).format("YYYY"); // Format the year as '2023'

    return { month, year };
  };
  const customHeader = (date) => {
    return (
      <View
        style={{
          width: "100%",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 10,
          paddingHorizontal:10
        }}
      >
        <TouchableOpacity
          onPress={() => {
            handleMonthChange("subtract");
          }}
          disabled={
            getMonthAndYear(selectedDate).month == "January" ? true : false
          }
        >
      <AntDesign name="left" size={18} color="black" />
        </TouchableOpacity>

        <View style={{ flexDirection: "row" }}>
          <TouchableOpacity
            onPress={() => calType=="month"?setCalType(""): setCalType("month")}
            style={styles.yearWrapper}
          >
            <Text
             
            >
              {getMonthAndYear(selectedDate).month}
            </Text>
            <AntDesign style={{marginLeft:4}} name="down" size={14} color="black" />
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => setCalType("year")}
            style={styles.yearWrapper}
          >
            <Text
             >
              {getMonthAndYear(selectedDate).year}
              </Text>
          </TouchableOpacity>
        </View>
        <TouchableOpacity
          onPress={() => {
            handleMonthChange("add");
          }}
          disabled={
            getMonthAndYear(selectedDate).month == "December" ? true : false
          }
        >
        <AntDesign name="right" size={18} color="black" />
        </TouchableOpacity>
      </View>
    );
  };
  const handleMonthSelection = (selectedMonth: any) => {
    const updatedDate = moment(selectedDate).month(selectedMonth).toDate();
    setSelectedDate(updatedDate);
    setCalType("cal");
  };
  return (
    <>
     {customHeader()}
     {
          calType == "month" ? (
          <MonthPicker
            onSelection={(month) => handleMonthSelection(month)}
            selectedValue={getMonthAndYear(selectedDate).month}
          />
        ) : (
      <Calendar
        style={styles.calendar}
        renderArrow={(direction) => {
          if (direction == 'left') return <Octicons name="chevron-left" size={24} color="black" />;
          if (direction == 'right') return <Octicons name="chevron-right" size={24} color="black" />;
        }}
        disableArrowLeft={true}
        hideArrows={true}
        disableArrowRight={true}
        initialDate={CalendarUtils.getCalendarDateString(selectedDate)}
        renderHeader={(date) => <></>}
        markingType={'custom'}
        onMonthChange={(month) => {
          onMonthChange(month);
          console.log('month changed', month);
        }}
        enableSwipeMonths={true}
        current={moment(selectedDate).format('YYYY-MM-DD')}
        markedDates={markers}
        theme={{
          backgroundColor: "white",
          calendarBackground: "white",
          textSectionTitleColor:"black",
          textSectionTitleDisabledColor:"black",
          selectedDayBackgroundColor:"black",
          selectedDayTextColor:"black",
          todayTextColor:"red",
          dayTextColor:"black",
          textDisabledColor:"black",
          dotColor:"black",
          selectedDotColor:"black",
          arrowColor:"red",
          monthTextColor:"black",
          indicatorColor:"black",
          textDayFontFamily: "Lato-Regular",
          textMonthFontFamily: "Lato-Regular",
          textDayHeaderFontFamily: "Lato-Regular",
          textDayFontWeight: "300",
          textMonthFontWeight: "bold",
          textDayHeaderFontWeight: "300",
          textDayFontSize: 16,
          textMonthFontSize: 12,
          textDayHeaderFontSize: 12,
        }}
      />)}
    </>
  );
};

const styles = StyleSheet.create({
  calendar: {
    marginBottom: 10,
  },
  switchContainer: {
    flexDirection: 'row',
    margin: 10,
    alignItems: 'center',
  },
  switchText: {
    margin: 10,
    fontSize: 16,
  },
  text: {
    textAlign: 'center',
    padding: 10,
    backgroundColor: 'lightgrey',
    fontSize: 16,
  },
  disabledText: {
    color: 'grey',
  },
  defaultText: {
    color: 'purple',
  },
  customCalendar: {
    height: 250,
    borderBottomWidth: 1,
    borderBottomColor: 'lightgrey',
  },
  customDay: {
    textAlign: 'center',
  },
  customHeader: {
    backgroundColor: '#FCC',
    flexDirection: 'row',
    justifyContent: 'space-around',
    marginHorizontal: -4,
    padding: 8,
  },
  yearWrapper: {
    borderRadius: 4,
    borderColor: "grey",
    borderWidth: 1,
    padding: 6,
    flexDirection: "row",
    alignItems: "center",
    marginRight: (8),
  },
});

CalenderComponent.propTypes = {
  minDate: PropTypes.string,
  maxDate: PropTypes.string,
  selectedDate: PropTypes.string,
  disabledDates: PropTypes.arrayOf(PropTypes.string),
  onSetDatePress: PropTypes.func,
  onClosePress: PropTypes.func.isRequired,
  accessibilityLabel: PropTypes.string,
};
CalenderComponent.defaultProps = {
  minDate: format(new Date(), 'yyyy-MM-dd'),
  maxDate: format(addYears(new Date(), 40), 'yyyy-MM-dd'),
  selectedDate: null,
  disabledDates: [],
  onSetDatePress: () => {},
  accessibilityLabel: 'datePicker',
};
export default CalenderComponent;
