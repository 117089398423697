import { useState, useEffect } from 'react';
import ajax from '../helpers/ajaxHelper';

const useUtilsProvider = (arrUrl) => {
  const [response, setResponse] = useState([]);
  const [error, setError] = useState('');

  const fetchData = async () => {
    try {
      // Use Promise.allSettled to fetch data from all URLs in arrUrl
      const results = await Promise.allSettled(
        arrUrl.map((url) => ajax.get(url))
      );

      // Process the results
      const responses = results.map((result, index) => {
        if (result.status === 'fulfilled') {
          return { success: true, data: result.value?.data, url: arrUrl[index] };
        } else {
          return { success: false, error: result.reason, url: arrUrl[index] };
        }
      });

      // Update state with processed responses
      setResponse(responses);
    } catch (err) {
      console.error('Unexpected error:', err);
      setError('An unexpected error occurred.');
    }
  };

  useEffect(() => {
    if (arrUrl && arrUrl.length > 0) {
      fetchData();
    }
  }, []);

  return { response, error };
};

export default useUtilsProvider;

