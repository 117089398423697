import React, { useEffect, useMemo, useState } from 'react';
import { View, Image, FlatList, StyleSheet } from 'react-native';
import Box from '../../components/box';
import Text from '../../components/text';
import getPlatformType from '../../helpers/helpers';
import {
  Container,
  ContainerScrollView,
  TextInputWrapper,
  Row,
  HorizontalLine,
  RowCol,
  RowContainer,
  LeftContainer,
  MiddleContainer,
  RightContainer,
} from './index.style';
import moment from 'moment';
import Button from '../../components/button/Button';
import DrawerMenu from '../drawerMenu/DrawerMenu';
import ListCard from '../../components/listCard';
import ItemCounter from '../../components/itemCounter';
import DropDownPicker from '../../components/picker/DropDownPicker';
import useUtilsProvider from '../../hooks/useUtilsProvider';
import Urls from '../../services/Urls';
import { DashedContainer } from '../../components/box/Box.style';
import { Entypo, Ionicons } from '@expo/vector-icons';
import ajax from '../../helpers/ajaxHelper';
import { useRoute } from '@react-navigation/native';
import MarkAsComplete from '../../components/markAsComplete/MarkAsComplete';
import drawerHoc from '../../components/drawerHOC/DrawerHOC';
import { navigate } from '../../navigation/RootNavigation';
import TextInputComponent from '../../components/textinput/TextInputComponent';
import Colors from '../../constants/Colors';
import Knob from '../../components/knob';

var layoutType = getPlatformType();

const getColorForCertificate = (cer) => {
  if (cer?.length > 0 && cer[0]?.expiry_date) {
    return '#000';
  }
  return 'red';
};

const getColorForExpiry = (cer) => {
  if (cer?.length > 0 && cer[0]?.expiry_date) {
    if (moment(cer[0]?.expiry_date) < moment()) {
      return 'red';
    }
    return '#000';
  } else {
    return 'red';
  }
};

const getColorForLicense = (cer) => {
  if (cer?.length > 0 && (cer[0]?.status == 'Yes' || cer[0]?.status == 'Not Applicable')) {
    return '#000';
  }
  return 'red';
};

export const testingDetectorDate = (testDate) => {
  if (testDate) {
    const addOneYear = moment(testDate).add(1, 'y');
    return addOneYear > moment() ? '#000' : 'red';
  }
};

const PropertyDetailsReadOnly = ({ navigation }) => {
  const route = useRoute();
  const [state, setState] = useState({
    images: [],
    gas_provider: null,
    water_provider: null,
    wifi_provider: null,
    certificates: {},
    quantity: {},
    percentage: '',
  });
  const [gasData, setGasData] = useState([]);
  const [waterData, setWaterData] = useState([]);
  const [wifiData, setWifiData] = useState([]);
  const [councilData, setCouncilData] = useState([]);
  const [elecData, setElecData] = useState([]);
  const [selectedProviders, setSelectedProviderData] = useState([]);

  // Memoize the arrUrl array to prevent unnecessary re-renders
  const arrUrl = useMemo(
    () => [
      Urls.GasProvider,
      Urls.WaterProvider,
      Urls.WifiProvider,
      Urls.CouncilProvider,
      Urls.ElectricityProvider,
    ],
    []
  );

  const { response } = useUtilsProvider(arrUrl);
  console.log("useUtilsProvider response",response)
  const getFormattedData = (data, id) => {
    console.log("getFormattedData",data)
    if (data.data?.payload && id?.value) {
      let formattedData = data.data?.payload;
      let temp = [];
      temp = formattedData.filter((element) => element.id.toString() == id?.value.toString());

      if (temp && temp.length) {
        return temp[0].name;
      } else return 'N/A';
    } else return 'N/A';
  };

  const getCertificateDate = (cer) => {
    if (cer?.length > 0 && cer[0]?.expiry_date) {
      return moment(cer[0]?.expiry_date).format('MM/DD/YYYY');
    }
    return '';
  };

  const getDetectorDate = (date) => {
    if (date) {
      return moment(date).format('MM/DD/YYYY');
    }
    return '';
  };

  useEffect(() => {
    if (response && response?.length) {
      let gasData = getFormattedData(response[0], state?.water_provider);
      setGasData(gasData);
      let waterData = getFormattedData(response[1], state?.gas_provider);
      setWaterData(waterData);
      let wifiData = getFormattedData(response[2], state?.wifi_provider);
      setWifiData(wifiData);
      let councilData = getFormattedData(response[3], state?.council_provider);
      setCouncilData(councilData);
      let eleccData = getFormattedData(response[4], state?.electricity_provider);
      setElecData(eleccData);
    }
  }, [response, state]);
  const _handleChange = (e) => {
    setState({ ...state, [e.label]: e.value });
  };


  useEffect(() => {
    ajax
      .get(Urls.Properties + '/' + route?.params?.id)
      .then(({ data }) => {
        if (data.status) {
          setState({
            ...state,
            ...{
              images: data?.payload?.images && data?.payload?.images.length ? data?.payload?.images : [],
              quantity: data?.payload?.quantity,
              name: data.payload?.name,
              address_line1: `${data.payload?.address_line1}, ${data.payload?.city}, ${data.payload?.country}`,
              description: data?.payload?.description,
              certificates: data.payload?.certificates,
              licenses: data.payload?.licenses,
              is_carbon_monoxide_detector_available_in_every_room:
                data.payload?.is_carbon_monoxide_detector_available_in_every_room,
              is_smoke_detector_available_on_every_floor: data.payload?.is_smoke_detector_available_on_every_floor,
              finance: data.payload?.finance || [],
              gas_provider: data?.payload?.wifi_provider ? { value: data?.payload?.wifi_provider.toString() } : null,
              water_provider: data?.payload?.water_provider ? { value: data?.payload?.water_provider } : null,
              wifi_provider: data?.payload?.wifi_provider ? { value: data?.payload?.wifi_provider } : null,
              electricity_provider: data?.payload?.electricity_provider
                ? { value: data?.payload?.electricity_provider }
                : null,
              percentage: data?.payload?.percentage,
              carbon_monoxide_detector_testing_date: data?.payload?.carbon_monoxide_detector_testing_date,
              smoke_detector_testing_date: data?.payload?.smoke_detector_testing_date,
            },
          });
          if (data?.payload?.providerDetails && data?.payload?.providerDetails?.length) {
            setSelectedProviderData(data?.payload?.providerDetails);
          }
        } else {
          // alert(data.message);
        }
      })
      .catch((error) => {
        console.log('error', error);
      });
  }, []);

  const navigateToPropertyDetails = () => {
    navigate('PropertyDetails', { id: route?.params?.id });
  };

  const getPropertyDetails = (quantity) => {
    const arr = [
      {
        key: 'Bedrooms',
        value: quantity?.Bedrooms || 0,
      },
      {
        key: 'Bathrooms',
        value: quantity?.Bathrooms || 0,
      },
      {
        key: 'Kitchens',
        value: quantity?.Kitchens || 0,
      },
      {
        key: 'Living Room',
        value: quantity?.hasOwnProperty('Living Room') ? quantity['Living Room'] : 0,
      },
      {
        key: 'Dining Room',
        value: quantity?.hasOwnProperty('Dining Room') ? quantity['Dining Room'] : 0,
      },
      {
        key: 'Office',
        value: quantity?.Office || 0,
      },
      {
        key: 'Utility Area',
        value: quantity?.hasOwnProperty('Utility Area') ? quantity['Utility Area'] : 0,
      },
      {
        key: 'Basement',
        value: quantity?.Basement || 0,
      },
      {
        key: 'Lift',
        value: quantity?.Lift || 0,
      },
      {
        key: 'Conservatory',
        value: quantity?.Conservatory || 0,
      },
      {
        key: 'Other rooms',
        value: quantity?.hasOwnProperty('Other rooms') ? quantity['Other rooms'] : 0,
      },
      {
        key: 'Garden',
        value: quantity?.Garden || 0,
      },
      {
        key: 'Parking Space',
        value: quantity?.hasOwnProperty('Parking Space') ? quantity['Parking Space'] : 0,
      },
      {
        key: 'Other',
        value: quantity?.Other || 0,
      },
      {
        key: 'Garage',
        value: quantity?.Garage || 0,
      },
    ];
    return arr;
  };

  const renderItem = () => <RightContainer></RightContainer>;

  return (
    <>
      <ListCard
        leftIcon={'view-list-outline'}
        description={
          'Below is a detailed overview of your property. If you need to edit any items please go back to the property overview page and make the necessary changes to each section.'
        }
        title={'Property Details'}
        showPropertyCard={route.params?.name}
      />
      <Box
        style={{
          flexDirection: layoutType == 'phone' ? 'column' : 'row',
          alignItems: layoutType == 'phone' ? 'flex-start' : 'center',
        }}
      >
        <If condition={layoutType !== 'phone'}>
          <Ionicons name="home-outline" size={80} color={Colors.light.purple} />
        </If>
        <View style={{ marginLeft: 20, flex: 1 }}>
          <Text fontSize={18} bold>
            {state.name}
          </Text>
          <Text fontSize={15} marginTop={20}>
            {state.address_line1}
          </Text>
        </View>
        <View style={{ alignSelf: 'center', marginTop: 20 }}>
          <Knob
            size={90}
            strokeWidth={10}
            progressPercent={state?.percentage ? Math.round(state?.percentage) : 0}
            text={'Complete'}
            textColor={Colors.light.purple}
            pgColor={Colors.light.purple}
          />
        </View>
      </Box>

      <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: 24 }}>
        <Text fontSize={18} bold>
          Property Details
        </Text>
        <Text onPress={navigateToPropertyDetails} fontSize={15} bold color="#6D08C0">
          Edit
        </Text>
      </View>
      <Box style={{ marginTop: 15, justifyContent: 'space-between' }}>
        <FlatList
          data={getPropertyDetails(state.quantity)}
          keyExtractor={(item) => item.key}
          numColumns={layoutType == 'phone' ? 2 : 3}
          renderItem={({ item }) => {
            return (
              <View style={{ width: layoutType == 'phone' ? '50%' : '30%', alignItems: 'center', marginBottom: 20 }}>
                <Text fontSize={18} bold>
                  {item.key}
                </Text>
                <Text color={'#9F78FF'} fontSize={18} marginTop={10}>
                  {item.value}
                </Text>
              </View>
            );
          }}
        />
      </Box>

      <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: 24 }}>
        <Text fontSize={18} bold>
          Property Photos
        </Text>
        <Text onPress={navigateToPropertyDetails} fontSize={15} bold color="#6D08C0">
          Edit
        </Text>
      </View>
      <Box style={{ marginTop: 24, marginBottom: 24 }}>
        <View style={{ marginTop: '2%', flexDirection: 'row', flexWrap: 'wrap' }}>
          {state.images ? (
            state.images.map((data, index) => (
              <DashedContainer
                style={{
                  marginRight: '2%',
                  marginBottom: '2%',
                  height: 100,
                  width: 200,
                  borderRadius: '5%',
                }}
              >
                <Image
                  source={{ uri: data.openbrixName }}
                  style={{ height: '100%', width: '100%', borderRadius: '5%' }}
                />
              </DashedContainer>
            ))
          ) : (
            <Text>No Photo Present</Text>
          )}
        </View>
      </Box>

      <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: 24 }}>
        <Text fontSize={18} bold>
          Utilities
        </Text>
        <Text onPress={() => navigate('Utilities', { id: route?.params?.id })} fontSize={15} bold color="#6D08C0">
          Edit
        </Text>
      </View>
      <Box style={{ marginTop: 15 }}>
        <View
          style={{
            flexDirection: layoutType == 'phone' ? 'column' : 'row',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <View style={{ width: layoutType == 'phone' ? '100%' : '49%' }}>
            <TextInputComponent
              // value={state?.finance[0]?.mortgage_monthly_amount || 0}
              title={'Water Provider'}
              onChangeText={(e) => {}}
            />
          </View>
          <View style={{ width: layoutType == 'phone' ? '100%' : '49%' }}>
            <TextInputComponent
              // value={state?.finance[0]?.mortgage_monthly_amount || 0}
              title={'Council'}
              onChangeText={(e) => {}}
            />
          </View>
        </View>
        <View style={{ flexDirection: layoutType == 'phone' ? 'column' : 'row', justifyContent: 'space-between' }}>
          <View style={{ width: layoutType == 'phone' ? '100%' : '49%' }}>
            <TextInputComponent
              // value={state?.finance[0]?.mortgage_monthly_amount || 0}
              title={'Gas Provider'}
              onChangeText={(e) => {}}
            />
          </View>
          <View style={{ width: layoutType == 'phone' ? '100%' : '49%' }}>
            <TextInputComponent
              // value={state?.finance[0]?.mortgage_monthly_amount || 0}
              title={'Electricity Provider'}
              onChangeText={(e) => {}}
            />
          </View>
        </View>
      </Box>

      <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: 24 }}>
        <Text fontSize={18} bold>
          Certificate
        </Text>
        <Text
          onPress={() => {
            navigation.navigate('Certification', {
              id: route?.params?.id,
              key: 'certificate_details_status',
            });
          }}
          fontSize={15}
          bold
          color="#6D08C0"
        >
          Edit
        </Text>
      </View>
      <Box style={{ marginTop: 15 }}>
        <View style={style.parentRowWrapper}>
          <Text
            width={layoutType == 'phone' ? '100%' : '30%'}
            color={getColorForCertificate(state?.certificates?.gas_certificate)}
            fontSize={15}
            marginBottom={10}
          >
            Gas Certificate
          </Text>
          <View style={style.rowWrapper}>
            <Text fontSize={15} bold color={getColorForExpiry(state?.certificates?.gas_certificate)}>
              Expiry Date
            </Text>
            <View style={{ width: layoutType == 'phone' ? '50%' : '50%' }}>
              <TextInputComponent
                style={{}}
                onChangeText={(e) => {}}
                isDate
                date={getCertificateDate(state?.certificates?.gas_certificate)}
              />
            </View>
          </View>
        </View>
        <View style={style.parentRowWrapper}>
          <Text
            width={layoutType == 'phone' ? '100%' : '30%'}
            fontSize={15}
            marginBottom={10}
            color={getColorForCertificate(state?.certificates?.eicr_certificate)}
          >
            EICR Certificate
          </Text>
          <View style={style.rowWrapper}>
            <Text fontSize={15} bold width="50%" color={getColorForExpiry(state?.certificates?.eicr_certificate)}>
              Expiry Date
            </Text>
            <View style={{ width: layoutType == 'phone' ? '50%' : '50%' }}>
              <TextInputComponent
                onChangeText={(e) => {}}
                isDate
                date={getCertificateDate(state?.certificates?.eicr_certificate)}
              />
            </View>
          </View>
        </View>
        <View style={style.parentRowWrapper}>
          <Text
            width={layoutType == 'phone' ? '100%' : '30%'}
            fontSize={15}
            marginBottom={10}
            color={getColorForCertificate(state?.certificates?.epc_certificate)}
          >
            EPC Certificate
          </Text>
          <View style={style.rowWrapper}>
            <Text fontSize={15} bold color={getColorForExpiry(state?.certificates?.epc_certificate)}>
              Expiry Date
            </Text>
            <View style={{ width: layoutType == 'phone' ? '50%' : '50%' }}>
              <TextInputComponent
                // onChangeText={(e) => {}}
                isDate
                date={getCertificateDate(state?.certificates?.epc_certificate)}
              />
            </View>
          </View>
        </View>
      </Box>

      <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: 24 }}>
        <Text fontSize={18} bold>
          License
        </Text>
        <Text
          onPress={() => {
            navigation.navigate('Licensing', {
              id: route?.params?.id,
              key: 'license_details_status',
            });
          }}
          fontSize={15}
          bold
          color="#6D08C0"
        >
          Edit
        </Text>
      </View>
      <Box style={{ marginTop: 15 }}>
        <View style={style.parentRowWrapper}>
          <Text width="30%" fontSize={15} color={getColorForLicense(state?.licenses?.hmo_license)}>
            HMO License
          </Text>
          <View style={style.rowWrapper}>
            <Text bold width="30%" fontSize={15} color={getColorForExpiry(state?.licenses?.hmo_license)}>
              Expiry Date
            </Text>
            <View style={{ width: layoutType == 'phone' ? '50%' : '50%' }}>
              <TextInputComponent
                onChangeText={(e) => {}}
                isDate
                date={getCertificateDate(state?.licenses?.hmo_license)}
              />
            </View>
          </View>
        </View>
        <View style={style.parentRowWrapper}>
          <Text width="30%" fontSize={15} color={getColorForLicense(state?.licenses?.selective_license)}>
            Selective Licence
          </Text>
          <View style={style.rowWrapper}>
            <Text bold width="30%" fontSize={15} color={getColorForExpiry(state?.licenses?.selective_license)}>
              Expiry Date
            </Text>
            <View style={{ width: layoutType == 'phone' ? '50%' : '50%' }}>
              <TextInputComponent
                onChangeText={(e) => {}}
                isDate
                date={getCertificateDate(state?.licenses?.selective_license)}
              />
            </View>
          </View>
        </View>
      </Box>

      <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: 24 }}>
        <Text fontSize={18} bold>
          Habitation Requirements
        </Text>
        <Text
          onPress={() => {
            navigation.navigate('Requirements', {
              id: route?.params?.id,

              key: 'property_requirements_status',
            });
          }}
          fontSize={15}
          bold
          color="#6D08C0"
        >
          Edit
        </Text>
      </View>
      <Box style={{ marginTop: 15 }}>
        <View style={style.parentRowWrapper}>
          <Text width="30%" fontSize={15} color={state?.is_smoke_detector_available_on_every_floor ? '#000' : 'red'}>
            Smoke Detector
          </Text>
          <View style={style.rowWrapper}>
            <Text
              bold
              width="30%"
              fontSize={15}
              color={testingDetectorDate(state?.carbon_monoxide_detector_testing_date)}
            >
              Last Check Date
            </Text>

            <View style={{ width: layoutType == 'phone' ? '50%' : '50%' }}>
              <TextInputComponent
                onChangeText={(e) => {}}
                isDate
                date={getDetectorDate(state?.carbon_monoxide_detector_testing_date)}
              />
            </View>
          </View>
        </View>
        <View style={style.parentRowWrapper}>
          <Text
            width="30%"
            fontSize={15}
            color={state?.is_carbon_monoxide_detector_available_in_every_room ? '#000' : 'red'}
          >
            Carbon M Detector
          </Text>
          <View style={style.rowWrapper}>
            <Text bold width="30%" fontSize={15} color={testingDetectorDate(state?.smoke_detector_testing_date)}>
              Last Check Date
            </Text>
            <View style={{ width: layoutType == 'phone' ? '50%' : '50%' }}>
              <TextInputComponent
                onChangeText={(e) => {}}
                isDate
                date={getDetectorDate(state?.smoke_detector_testing_date)}
              />
            </View>
          </View>
        </View>
      </Box>
    </>
  );
};

const style = StyleSheet.create({
  rowWrapper: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    width: layoutType == 'phone' ? '100%' : '70%',
  },
  parentRowWrapper: {
    flexDirection: layoutType == 'phone' ? 'column' : 'row',
    marginBottom: 20,
  },
});

export default drawerHoc({
  Component: PropertyDetailsReadOnly,
  showBackButton: true,
});
